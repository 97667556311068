import React, { useState, ChangeEvent, FormEvent } from 'react';
import { EMAIL_REGEX } from '../../../utils/helpers';
import { lang } from '../../../common/i18n';
import { Recaptcha } from '../../../utils/google-recaptcha';
import { urlParams } from '../../../utils/helpers';

declare var window: any;
interface ForgotPasswordFormProps {
    errorMessage?: string;
    captcha?: Recaptcha;
    langId?: number;
    loginPageLocation: string;
}

const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
    const { errorMessage, langId, loginPageLocation, captcha } = props;

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleEmailValidation = emailStr => {
        if (emailStr) {
            if (!EMAIL_REGEX.test(emailStr)) {
                setError(lang('login.newsletter.email.error'));
            } else {
                setError('');
            }
        }
    };

    const recoverPassword = async event => {
        event.preventDefault();
        const base = window.location;
        let url = new URL(
            base.origin +
                base.pathname +
                '?' +
                urlParams({
                    page: 'forgot_password',
                    cmd: 'submit_email',
                    user_email: email,
                    lang: langId,
                    response: window.grecaptcha.getResponse(),
                })
        );
        try {
            const response = await fetch(url.toString(), {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(await response.text());
            } else {
                window.location.href = loginPageLocation + '?success=reset_password_email_sent';
            }
        } catch (err) {
            setError(err.message);
            window.grecaptcha.reset();
        }
    };

    return (
        <div className="forgot-password-container">
            <h1 className="ld_h1">{lang('login.forgot_your_password.label')}</h1>
            <div className="text-small">
                {lang('login.forgot_password.reset_password_steps.title')}:
                <ol>
                    <li>{lang('login.forgot_password.enter_email_address_click_send.label')}</li>
                    <li>{lang('login.forgot_password.go_to_inbox_for_recovery_email.label')}</li>
                    <li>{lang('login.forgot_password.follow_password_recovery_instructions.label')}</li>
                </ol>
            </div>

            <div className="alert" id="error-message">
                {errorMessage && lang(errorMessage)}
            </div>

            <form id="forgot-password-form" data-captcha-key={captcha['key']}>
                <div className="input-container">
                    <label htmlFor="user_email">{lang('login.forgot_password.email.label')}</label>
                    <input
                        id="user_email"
                        onChange={(evt: ChangeEvent<HTMLInputElement> & FormEvent) => {
                            setEmail(evt.target.value);
                            handleEmailValidation(evt.target.value);
                        }}
                        className={!error ? 'email-input' : 'email-input error'}
                        name="user_email"
                        type="email"
                        data-msg={lang('generic.email_input.error')}
                        required
                    />
                    <div className="ld_error">{!!error && error}</div>
                </div>
                <input type="hidden" name="lang" value={langId} />
                <input type="hidden" name="response" id="response" value="" />
                <div id="ld-recaptcha" />
                <div className="flex flex-column">
                    <button className="button" disabled={!(email && !error)} onClick={recoverPassword}>
                        {lang('login.forgot_password.recover_password.button')}
                    </button>
                    <a id="back-to-login-link" href={loginPageLocation + '/'}>
                        {lang('login.back_to_login.label')}
                    </a>
                </div>
            </form>
        </div>
    );
};
export default ForgotPasswordForm;
