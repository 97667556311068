import React from 'react';
import LoginViewBase from '../Login/LoginViewBase';
import ResetPasswordForm from './ResetPasswordForm';
import { PromotionalIframeProps } from '../PromotionalIframe';

export interface ResetPasswordViewProps {
    releaseNotesUrl?: string;
    loginUrl: string;
}

const ResetPasswordView = (props: ResetPasswordViewProps & PromotionalIframeProps) => {
    const { releaseNotesUrl, promotionalIframeUrl } = props;

    return (
        <LoginViewBase releaseNotesUrl={releaseNotesUrl} promotionalIframeUrl={promotionalIframeUrl}>
            <div>
                <ResetPasswordForm {...props} />
            </div>
        </LoginViewBase>
    );
};

export default ResetPasswordView;
